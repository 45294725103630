import React, { useRef } from 'react';
// import { parallaxedHolder } from './Parallaxed.module.scss';
import { motion, useSpring, useTransform, useScroll } from 'framer-motion';

export default function Parallaxed({ children, className }) {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({ target: ref, offset: ['start end', 'start start'] });
  const distance = 100;

  const opacityTransformed = useTransform(scrollYProgress, [0, 0.6], [0.5, 1]);
  const opacity = useSpring(opacityTransformed, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  const yTransformed = useTransform(scrollYProgress, [0, 1], [distance, 0]);
  const y = useSpring(yTransformed, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  const xTransformed = useTransform(scrollYProgress, [0, 0.6], [distance, 0]);
  const x = useSpring(xTransformed, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  // const xInvertedTransformed = useTransform(scrollYProgress, [0, 0.6], [-distance, 0]);
  // const xInverted = useSpring(xInvertedTransformed, {
  //   stiffness: 100,
  //   damping: 30,
  //   restDelta: 0.001,
  // });

  return (
    <div ref={ref} className={className}>
      <motion.div className="parallaxedInner" style={{ y }}>
        {children}
      </motion.div>
    </div>
  );
}
