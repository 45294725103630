import React, { useRef } from 'react';
import { Link } from 'gatsby';
import Seo from '../components/Seo';
import { indexHolder } from './index.module.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { upFadeIn, staggerChildren } from '../lib/variants.js';
import { motion, useScroll, useTransform } from 'framer-motion';
import Parallaxed from '../components/Parallaxed';

export default function IndexPage() {
  const headerRef = useRef(null);

  const { scrollYProgress } = useScroll();

  const headerOpacity = useTransform(scrollYProgress, [0, 0.08], [1, 0]);

  return (
    <article className={indexHolder}>
      <header ref={headerRef}>
        <motion.div className="headerInner" style={{ opacity: headerOpacity }}>
          <motion.h1 variants={staggerChildren}>
            <motion.span variants={upFadeIn}>Victoria</motion.span> <motion.span variants={upFadeIn}>Owen</motion.span>{' '}
            <motion.span variants={upFadeIn}>Creative.</motion.span>
          </motion.h1>
          <div className="information">
            <p>Art Director. Based in London.</p>
            <nav>
              <ul className="links">
                <li>
                  <Link to="/work" title="Work">
                    <span>Work</span>
                  </Link>
                </li>
                <li>
                  <Link to="/contact/#about" title="About">
                    <span>About</span>
                  </Link>
                </li>
                <li>
                  <Link to="/contact/#contact" title="Contact">
                    <span>Contact</span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </motion.div>
      </header>
      <section className="rows">
        <div className="row row1 lrHolder">
          <div className="lhs">
            <div className="inner">
              <p>Set design of festive photoshoot for Gemfields</p>
            </div>
          </div>
          <Parallaxed className="rhs">
            <StaticImage src="../images/home/home1.jpg" alt="Victoria Owen Creative" />
          </Parallaxed>
        </div>
        <div className="row row2 lrHolder">
          <Parallaxed className="lhs">
            <StaticImage src="../images/home/home2.jpg" alt="Victoria Owen Creative" />
          </Parallaxed>
          <div className="rhs">
            <div className="inner">
              <p>Design and build for feature areas at Money2020 Europe.</p>{' '}
            </div>
          </div>
        </div>
        <Parallaxed className="row row3">
          <StaticImage src="../images/home/home3.jpg" alt="Victoria Owen Creative" />
        </Parallaxed>
        <div className="row row4">
          <Parallaxed className="lhs">
            <StaticImage src="../images/home/home4.jpg" alt="Victoria Owen Creative" />
          </Parallaxed>
          <div className="rhs">
            <div className="inner">
              <p>Set design for LADbible campaign in partnership with Ben and Jerry’s.</p>
            </div>
          </div>
        </div>
        <div className="row row5">
          <Parallaxed className="lhs">
            <StaticImage src="../images/home/home5.jpg" alt="Victoria Owen Creative" />
          </Parallaxed>
          <div className="rhs">
            <div className="inner">
              <p>Prop design and build for Jungle Creations’ Four Nine platform.</p>
            </div>
          </div>
        </div>
      </section>
    </article>
  );
}

export const Head = () => <Seo title="Victoria Owen Creative - Art director. Based in London." />;
